<template>
    <section>
        <div class="category-card overflow-hidden border br-10" @mouseover="showCover = true" @mouseleave="showCover = false">
            <div class="d-flex justify-content-between bg-general text-white px-3 py-2 f-500 f-14">
                <div class="name-size tres-puntos">{{ categoryName }}</div>
                <div>{{ date | helper-fecha('DD/MM/YYYY') }}</div>
            </div>
            <section class="position-relative">
                 <div class="px-3 pt-1 pb-2 f-12">
                    <div class="row justify-content-between f-500 py-1 lh-30">
                        <div class="col-6 ">Etapas:</div>
                        <div class="col-6 text-center">{{ stages }}</div>
                    </div>
                    <div class="row justify-content-between f-500 py-1 lh-30">
                        <div class="col-6">Configuraciones:</div>
                        <div class="col-6 text-center">{{ configurations }}</div>
                    </div>
                    <div class="row justify-content-between f-500 py-1 lh-30">
                        <div class="col-6">Planes de mantenimiento:</div>
                        <div class="col-6 text-center">{{ maintenancePlans }}</div>
                    </div>
                    <div class="row justify-content-between f-500 py-1 lh-30">
                        <div class="col-6">Materiales activos:</div>
                        <div class="col-6 text-center">{{ activeMaterials }}</div>
                    </div>
                    <div class="row justify-content-between f-500 py-1 lh-30">
                        <div class="col-6">Materiales inactivos:</div>
                        <div class="col-6 text-center">{{ inactiveMaterials }}</div>
                    </div>
                    <div class="row justify-content-between f-500 py-1 lh-30 text-general">
                        <div class="col-6">Proveedor favorito:</div>
                        <div class="col-6 text-center tres-puntos">{{ favoriteProvider }}</div>
                    </div>
                </div>
                <hr class="m-0">
                <div class="bg-white row justify-content-between px-3 f-13">
                    <div class="col">Valor total:</div>
                    <div class="col text-center">{{formatoMoneda(totalValue)}}</div>
                </div>
                <div class="card-cover position-absolute cr-pointer h-100 w-100" :style="`opacity: ${showCover ? 1 : 0}`">
                    <div class="position-relative h-100 w-100" @click="showMaterialList">
                        <img class="h-100 obj-cover" :src="image | helper-storage('category')" alt="">
                        <div class="label text-white text-center position-absolute w-100 py-2">
                            Ingresar
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="card-buttons d-flex justify-content-center gap-1 py-4">
            <div class="icon-btn mx-1">
                <i class="icon-pencil f-22 cr-pointer text-white"
                :class="permitAction('almacen.categorias.editar') ? 'cr-pointer' : 'cr-not-allowed'" 
                @click="functionPermitAction('almacen.categorias.editar', ()=>{$emit('edit')})"
                />
            </div>
            <div class="icon-btn mx-1">
                <i class="icon-trash-can-outline f-22 text-white"
                :class="permitAction('almacen.categorias.eliminar') ? 'cr-pointer' : 'cr-not-allowed'" 
                @click="functionPermitAction('almacen.categorias.eliminar', ()=>{$emit('delete')})"
                />
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props:{
        id: { type: Number, required: true },
        categoryName: { type: String, required: true },
        image: { type: String },
        date: { type: String, default: '-' },
        stages: { type: Number, default: 0 },
        configurations: { type: Number, default: 0 },
        maintenancePlans: { type: Number, default: 0 },
        activeMaterials: { type: Number, default: 0 },
        inactiveMaterials: { type: Number, default: 0 },
        favoriteProvider: { type: String, default: '-'},
        totalValue: { type: Number, default: 0 }
    },
    data(){
        return {
            showCover: false,
        }
    },
	computed: {
		...mapGetters({
			user: 'auth/user'
		})
	},
    methods: {
        showMaterialList(){
            this.$router.push({ name: 'almacen.categorias.materiales', params: { id_categoria: this.id, name:this.categoryName }})
        }
    }
}
</script>

<style scoped lang="scss">
.category-card{
    width: 266px;
    .name-size{
        max-width: 140px;
    }
    .card-cover{
        transition: opacity .3s;
        img{
            height: calc(100% - 25px);
            width: 100%;
        }
        bottom: 0;
        left: 0;
        .label{
            background: rgba(112, 112, 112, .82);
            bottom: 0; left: 0;
        }
    }
}
.card-buttons{
    .icon-btn{
        border-radius: 5px;
        background: var(--text-general);
        &:hover{
            background: var(--color-general);
        }
    }
}
</style>