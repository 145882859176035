<template>
    <section class="categorias overflow-auto custom-scroll">
        <titulo-divisor titulo="Categorias" class="mx-0">
            <div class="row">
                <el-tooltip content="Agregar categoria" effect="light" placement="top" visible-arrow>
                    <div class="col-auto icon-option my-auto px-1">
                        <i class="icon-plus-circle f-30" 
                            :class="permitAction('almacen.categorias.agregar') ? 'cr-pointer' : 'cr-not-allowed'" 
                            @click="functionPermitAction('almacen.categorias.agregar', createCategory, [])"
                        />
                    </div>
                </el-tooltip>
                <div v-if="filterCount == 0" class="col-auto my-auto pl-1 icon-option">
                    <el-tooltip content="Filtro" placement="bottom" effect="light" visible-arrow>
                        <button class="btn btn-square32-5d ml-2 cr-pointer" @click="openFilterModal">
                            <i class="icon-filter text-white" />
                        </button>
                    </el-tooltip>
                </div>
                <div v-if="filterCount > 0" class="col-auto filtro-contador my-auto pl-1">
                    <div class="d-flex border-general br-4">
                        <button class="btn btn-square32-5d cr-pointer" @click="openFilterModal">
                            <i class="icon-filter text-white" />
                        </button>
                        <div class="d-middle px-2 cr-pointer">
                            <p class="f-12">{{ filterCount }}</p>
                            <i class="icon-close-circle f-11 text-muted pl-1" @click="reload" />
                        </div>
                    </div>
                </div>
            </div>
        </titulo-divisor>
        <category-list @paginate="reload"/>
        <modal-create-category ref="modalCreateCategory"/>
        <modal-filter-category ref="modalFilterCategory"/>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CategoryList from './CategoryList'
import modalCreateCategory from './partials/modalCreateCategory';
import modalFilterCategory from './partials/modalFilterCategory';
export default {
    components: { CategoryList, modalCreateCategory, modalFilterCategory },
    computed:{
        ...mapGetters({
			user: 'auth/user',
            filter: 'almacen/categorias/categorias/filter',
            ranges: 'almacen/categorias/categorias/ranges',
        }),
        filterCount(){
            return this.filter.count();
        }
    },
    async created(){
        this.getBreadcumbs([ 'almacen.main', 'almacen.categorias' ]);
        this.getSelects(['Action_get_select_providers']);
        await this.getCategoryRanges()
        await this.getCategoryList()
        this.getSelectCategoryNames();
    },
    methods:{
        ...mapActions({
            getCategoryList: 'almacen/categorias/categorias/Action_get_category_list',
            getCategoryRanges: 'almacen/categorias/categorias/Action_get_category_ranges',
            getSelectCategoryNames: 'almacen/categorias/categorias/get_select_category_names',
            clearFilter: 'almacen/categorias/categorias/clear_filter',
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
            getSelects: 'selects/selects/sync'
        }),
        createCategory(){
            this.$refs.modalCreateCategory.toggle();
        },
        openFilterModal(){
            this.$refs.modalFilterCategory.toggle();
        },
        async reload(page){
            this.clearFilter()
            await this.getCategoryList(page);
        }
    }
}
</script>