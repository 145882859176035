<template>
    <Modal :titulo="`${title} categoria`" ref="modalCreateCategory" tamano="modal-lg" adicional="Guardar" @adicional="beforeSaveCategory">
        <ValidationObserver ref="validator">
            <div class="row justify-content-center">
                <div class="col-10 mt-3 mb-4">
                    <required text="Campos obligatorios"/>
                </div>
                <div class="col-10 mb-4">
                    <ValidationProvider name="nombre" v-slot="{ errors }" rules="required">
                        <div class="row align-items-center">
                            <div class="col-4">
                                <label class="f-12 text-general f-500" for="nombre">Agregue nombre <required/></label>
                            </div>
                            <div class="col-8">
                                <el-input v-model="category.nombre" id="nombre" />
                                <vee-error :text="errors[0]"/>
                            </div>
                        </div>
                    </ValidationProvider>
                </div>
                <div class="col-10 mb-3">
                    <div class="row align-items-center mb-2">
                        <div class="col-4">
                            <label class="f-12 text-general f-500" for="nombre-proveedor">Agregue proveedor favorito</label>
                        </div>
                        <div class="col-8">
                            <el-select v-model="category.id_proveedor" clearable>
                                <el-option v-for="item in select_providers" class="tres-puntos" :key="item.id" :label="item.proveedor" :value="item.id" />
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="mx-auto cropper-size mb-2">
                        <clean-cropper 
                        ref="cleanCropper"
                        v-model="image"
                        :image="category.imagen"
                        />
                    </div>
                    <p class="text-center f-13">
                        Adjunte imágenes del producto
                    </p>
                </div>
            </div>
        </ValidationObserver>
    </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    data(){
        return {
            category: {
                id: null,
                nombre: '',
                imagen: null,
                id_proveedor: null,
            },
            image: null
        }
    },
    computed:{
        ...mapGetters({
            select_providers: 'selects/selects/select_providers',
            key: 'files/files/key',
        }),
        title(){
            return this.category.id ? 'Editar' : 'Agregar'
        }
    },
    methods: {
        ...mapActions({
            createCategory: 'almacen/categorias/categorias/Action_create_category',
            updateCategory: 'almacen/categorias/categorias/Action_update_category',
            uploadFile: 'files/files/Action_save_file_to_aws',
        }),
        toggle(){
            this.$refs.modalCreateCategory.toggle();
        },
        edit(category){
            const { id, nombre, imagen, id_proveedor_favorito: id_proveedor,  } = category
            this.category = { id, nombre, imagen, id_proveedor }
            this.$refs.modalCreateCategory.toggle();
        },
        async beforeSaveCategory(){
            const valid = await this.$refs.validator.validate()
            if(!valid) return

            const base64Image = this.image
            
            if(base64Image){
                await this.uploadFile({ 
                    file: await this.base64ToFile(base64Image),
                    path: '/almacen/categorias' 
                });
            }

            const payload = {
                ...this.category,
                id_proveedor: this.category.id_proveedor === '' ? null : this.category.id_proveedor,
                imagen: base64Image ? this.key : this.category.imagen
            }
            
            this.category.id
                ? await this.updateCategory(payload)
                : await this.createCategory(payload)

            this.cleanForm()
            this.toggle()
        },
        cleanForm(){
            this.category = {
                id: null,
                nombre: '',
                imagen: null,
                id_proveedor: null,
            }
            this.$refs.cleanCropper.reset();
            this.$refs.validator.reset()
        }
    }
}
</script>

<style scoped lang="scss">
.cropper-size{
    width: 110px;
    height: 110px;
}
</style>