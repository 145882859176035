<template>
    <modal-lateral ref="modalFilterCategory" titulo="Filtro">
        <div class="row mx-0 justify-content-center my-2">
            <div class="col-11 my-4">
                <p class="input-label-top">Nombre de la categoria</p>
                <el-select v-model="filter.nombre" clearable filterable placeholder="Seleccionar" size="small" class="w-100">
                    <el-option v-for="item in setect_category_names" :key="item.id" :label="item.name" :value="item.name" />
                </el-select>
            </div>
            <div class="col-11 my-4">
                <p class="input-label-top">Proveedor favorito</p>
                <el-select v-model="filter.id_proveedores" multiple clearable filterable placeholder="Seleccionar" size="small" class="w-100">
                    <el-option v-for="item in select_providers" style="width: 280px" :key="item.id" :label="item.proveedor" :value="item.id" />
                </el-select>
            </div>
            <div class="col-11 my-3">
                <p class="input-label-top">Rango etapas</p>
                <el-slider v-model="range_value_stage" range :min="range_etapas[0]" :max="range_etapas[1]" :disabled="disableStageSlider" />
            </div>
            <div class="col-11 my-3">
                <p class="input-label-top">Rango planes de mantenimientos</p>
                <el-slider v-model="range_value_mtto" range :min="range_planes_mantenimiento[0]" :max="range_planes_mantenimiento[1]" :disabled="disableMttoSlider" />
            </div>
            <div class="col-11 my-3">
                <p class="input-label-top">Rango de materiales</p>
                <el-slider v-model="range_value_material" range :min="range_materiales[0]" :max="range_materiales[1]" :disabled="disableMaterialSlider" />
            </div>
            <div class="col-6 my-3">
                <p class="input-label-top">Fecha de inicio</p>
                <el-date-picker v-model="filter.fecha_min" type="date" placeholder="Seleccionar un día" size="small" class="w-100" />
            </div>
            <div class="col-6 my-3">
                <p class="input-label-top">Fecha final</p>
                <el-date-picker v-model="filter.fecha_fin" type="date" placeholder="Seleccionar un día" size="small" class="w-100" />
            </div>
        </div>
        <div class="botones">
            <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="clearFilter">Limpiar</button>
            <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filterCategories">Filtrar</button>
        </div>
    </modal-lateral>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data(){
        return {
            category_names: [],
        }
    },
    computed:{
        ...mapGetters({
            select_providers: 'selects/selects/select_providers',
            setect_category_names: 'almacen/categorias/categorias/select_category_names',
            ranges: 'almacen/categorias/categorias/ranges',
        }),
        filter: {
            get() {
                return this.$store.getters['almacen/categorias/categorias/filter']
            },
            set(val){
                this.$store.commit('almacen/categorias/categorias/set_filter', val)
            }
        },
        range_materiales(){
            return [
                this.ranges?.materiales?.[0] ?? 0,
                this.ranges?.materiales?.[1] ?? 0,
            ]
        },
        range_etapas(){
            return [
                this.ranges?.etapas?.[0] ?? 0,
                this.ranges?.etapas?.[1] ?? 0,
            ]
        },
        range_planes_mantenimiento(){
            return [
                this.ranges?.planes_mantenimiento?.[0] ?? 0,
                this.ranges?.planes_mantenimiento?.[1] ?? 0,
            ]
        },
        range_value_stage: {
            get(){
                return [ this.filter.etapas_min, this.filter.etapas_max]   
            },
            set(val){
                this.filter.etapas_min = val[0]
                this.filter.etapas_max = val[1]
            }
        },
        range_value_mtto: {
            get(){
                return [ this.filter.planes_mantenimiento_min, this.filter.planes_mantenimiento_max]   
            },
            set(val){
                this.filter.planes_mantenimiento_min = val[0]
                this.filter.planes_mantenimiento_max = val[1]
            }
        },
        range_value_material: {
            get(){
                return [ this.filter.materiales_min, this.filter.materiales_max]   
            },
            set(val){
                this.filter.materiales_min = val[0]
                this.filter.materiales_max = val[1]
            }
        },
        disableStageSlider(){
            return this.ranges?.etapas?.every(el => el === this.range_etapas[0] && this.range_etapas[1] === el)
        },
        disableMttoSlider(){
            return this.ranges?.planes_mantenimiento?.every(el => el === this.range_planes_mantenimiento[0] && el === this.range_planes_mantenimiento[1])
        },
        disableMaterialSlider(){
            return this.ranges?.materiales?.every(el => el === this.range_materiales[0] && el === this.range_materiales[1])
        },

    },
    methods: {
        ...mapActions({
            getCategoryList: 'almacen/categorias/categorias/Action_get_category_list',
            clearFilter: 'almacen/categorias/categorias/clear_filter',
        }),
        toggle(){
            this.$refs.modalFilterCategory.toggle()
        },
        async filterCategories(){
            await this.getCategoryList()
            this.toggle();
        }
    }
}
</script>