<template>
    <section class="category-list d-flex flex-wrap mx-3">
        <template v-for="category in categories">
            <category-card
            :id="category.id"
            :key="category.id"
            :image="category.imagen"
            :category-name="category.nombre"
            :date="category.created_at"
            :stages="category.etapas"
            :configurations="category.cantidad_materiales"
            :maintenance-plans="category.planes_mantenimiento"
            :active-materials="category.materiales_activos"
            :inactive-materials="category.materiales_inactivos"
            :favorite-provider="(category.proveedor && category.proveedor.nombre) || '-'"
            :total-value="category.valor && parseFloat(category.valor) || 0"
            @edit="handleEditCategory(category)"
            @delete="handleDeleteCategory(category)"
            />
        </template>
        <div class="w-100 mx-auto text-center">
            <Pagination :pagination="pagination" v-on="$listeners"/>
        </div>
        <modal-create-category ref="modalEditCategory"/>
        <modal
        ref="modalEliminar" 
        titulo="Eliminar categoria" 
        btn-eliminar
        @eliminar="beforeDeleteCategory(category.id)"
        >
            <div class="row justify-content-center my-3">
                <div class="col-11 text-center">
                    <template v-if="category.cantidad_materiales > 0">
                        Se eliminarán {{category.cantidad_materiales}} materiales con esta categoría, ¿está seguro?
                    </template>
                    <template v-else>
                        ¿Está seguro de eliminar esta categoría?
                    </template>
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CategoryCard from './components/CategoryCard'
import ModalCreateCategory from './partials/modalCreateCategory'
export default {
    components: { CategoryCard, ModalCreateCategory },
    data(){
        return {
            category: {
                id: null,
                cantidad_materiales: 0,
            }
        }
    },

    computed:{
        ...mapGetters({
            categories: 'almacen/categorias/categorias/categories',
            pagination: 'almacen/categorias/categorias/pagination',
        })
    },
    methods:{
        ...mapActions({
            deleteCategory: 'almacen/categorias/categorias/Action_delete_category'
        }),
        handleEditCategory(category){
            this.$refs.modalEditCategory.edit(category);
        },
        handleDeleteCategory({id, cantidad_materiales}){
            this.category = { id, cantidad_materiales }
            this.$refs.modalEliminar.toggle()
        },
        async beforeDeleteCategory(id){
            await this.deleteCategory(id)
            this.$refs.modalEliminar.toggle()
        }
    }
}
</script>

<style scoped lang="scss">
.category-list{
    gap: 31px 61px;
}
</style>